.btn-primary {
    background-color: $primary-color !important;
    color: $white !important;
    border-radius: 0 !important;

    &:hover {
        color: $black !important;
    }
}

.btn-danger {
    background-color: $red !important;
    color: $white !important;
    border-radius: 0 !important;

    &:hover {
        color: $black !important;
    }
}

.btn-blue {
    background-color: $blue !important;
    color: $white !important;
    border-radius: 0 !important;

    &:hover {
        color: $black !important;
    }
}

.btn-circle-primary { 
    background-color: $primary-color !important; 
    color: $white !important;
    border-radius: 50% !important; 
}

.radio-group-button-primary {
    .ant-radio-button-wrapper-checked {
        border-color: $primary-color !important;
        color: $primary-color !important;
        // border-right-color: $primary-color !important;
    }

    .ant-radio-button-wrapper {
        width: 20% !important;
        height: 100% !important;
        text-align: center !important;
    }

    .ant-radio-button {
        position: relative !important;
    }
}

.basic-button {
    border: 1px solid $primary-color !important;

    &:hover {
        box-shadow: 0 0 5px $primary-color !important;
        color: $primary-color !important;
    }

    &:focus {
        box-shadow: 0 0 5px $primary-color !important;
        color: $primary-color !important;
    }
}