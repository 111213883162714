.ant-slider-track {
    background-color: none !important;
}

.ant-slider-handle {
    border: $primary-color 2px solid !important;
    background-color: $white !important;
    width: 8px !important;
    height: 8px !important;
    left: 7px !important;
    top: 7px !important;
}