.ps__rail-x {
    left: 0px;
    bottom: 0px;
}

.ps__rail-y {
    .ps_thumb-y {
        right: 0;
    }
}

.sidebar-layout {
    width: 120px;
    height: calc(100vh - 80px);
    position: fixed !important;
    top: 80px;

    .ant-menu-item-selected {
        background-color: $white !important;
        border-right: 4px solid darken($primary-color, 0.6) !important;

        .ant-row {
            color: darken($primary-color, 0.6) !important;
        }
    }
}

.product-detail-info-part {
    width: 100%;
    height: calc(100vh - 232px);
    position: relative !important;
}

.sidebar-menu-item {
    height: 80px !important;

    a {
        &:hover {
            color: darken($primary-color, 0.6) !important;
        }
    }

    .sidebar-menu-item-icon {
        margin-top: 10px;
    }
}

.sidebar-submenu-item {
    a {
        &:hover {
            color: darken($primary-color, 0.6) !important;
        }
    }
}

.submenu-layout {
    border-right: none !important;

    .ant-menu-item-selected {
        background-color: $white !important;

        .ant-row {
            color: darken($primary-color, 0.6) !important;
        }
    }
}

.report-sub-menu {
    .ant-menu-item-selected, .ant-menu-item:hover {
        color: $primary-color !important;

        .ant-menu-title-content {
            a {
                color: $primary-color !important;
            }
        }
    }

    // .ant-menu-item:hover {
    //     color: $primary-color !important;

    //     .ant-menu-title-content {
    //         a {
    //             color: $primary-color !important;
    //         }
    //     }
    // }

    // .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
    //     border-bottom: none !important;
    // }
    .ant-menu-item-selected::after, .ant-menu-item:hover::after, .ant-menu-item::after {
        border-bottom: none !important;
    }
}