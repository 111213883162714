.navbar {
    height: 80px !important;
    padding: 0px !important;
    position: fixed;
    width: 100%;
    background-color: $white !important;
    -moz-box-shadow:0 5px 5px rgba(182, 182, 182, 0.75);
    -webkit-box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
    box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
    z-index: 10;

    .logo {
        width: 100%;
        max-width: 170px;
        height: 50px;
        margin-left: 10px;
    }
}